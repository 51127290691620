@font-face {
    font-family: 'Questrial';
    src: url('../resources/Questrial-Regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../resources/Questrial-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}


body, html {
    height: 100%;
    font-family: "Questrial", "Courier", sans-serif;
}

.a4picons {
    color: #083c08;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.a4p-green {
    color: #083c08;
}



.a4p-green-light-background {
    background: #cdd9cd;
}

.a4p-green-background {
    background: #083c08;
}


a:link {
    color: white;
}

/* visited link */
a:visited {
    color: white;
}

/* mouse over link */
a :hover {
    color: white;
}

/* selected link */
a:active {
    color: white;
}

/* =======================================*/
/* Links in a4p-grün */
a:link.a4p-green {
    color: #083c08;
    font-weight: bold;
    text-decoration: underline;
}

/* visited link */
a:visited.a4p-green {
    color: #083c08;
    font-weight: bold;
    text-decoration: underline;
}

/* mouse over link */
a:hover.a4p-green {
    color: #083c08;
    font-weight: bold;
    text-decoration: underline;
}

/* selected link */
a:active.a4p-green {
    color: #083c08 !important;
    font-weight: bold;
    text-decoration: underline;
}

/* =======================================*/


.navbar-green {
    background-color: #083c08;
}

.card-title {
    font-weight: bold;
}

.hero-background {
    background: url("../resources/images/iStock-1180187740.jpg") no-repeat center;
    background-size: cover;
    height: 100vh;
}

.a4p-background {
    background: url("../resources/images/iStock-1128789418.jpg") center;
    background-size: cover;
}

.kontakt-background {
    background: #083c08;
    background-size: cover;
    height: 100vh;
}


@media (max-width: 576px) {
    .display-1 {
        font-size: 3rem !important;
        font-weight: 300;
        line-height: 1.0;
    }
}

h1 {
    font-size: 2rem !important;
}

h2 {
    font-size: 1.5rem !important;
}

h3 {
    font-size: 1.2rem !important;
}

body {
    font-size: 1rem !important;
}

.pt-6 {
    padding-top: 5rem !important;
}

.pt-7 {
    padding-top: 6rem !important;
}

.pt-8 {
    padding-top: 8rem !important;
}

.pt-10 {
    padding-top: 20vh !important;
}

.pb-10 {
    padding-bottom: 10rem !important;
}

.bottom-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.bottom-list-item {
    color: white;
}

.carousel {
    height: 500px;
    margin: auto;
}

.carousel-item {
    height: 500px;
    margin: auto;
}

.a4p-carousel {
    margin-left: 15%;
    margin-right: 15%;
}

@media (max-width: 576px) {
    .a4p-carousel-height {
        height: 800px
    }
}

/*.carousel-inner {*/
/*    position: absolute;*/
/*    right: 15%;*/
/*    bottom: 20px;*/
/*    left: 15%;*/
/*    z-index: 10;*/
/*    padding-top: 20px;*/
/*    padding-bottom: 20px;*/
/*    color: #fff;*/
/*    text-align: center;*/
/*}*/
